import {
  BOARD_FETCH, BOARD_FETCH_REQUESTED, BOARD_FETCH_SUCCEEDED, BOARD_FETCH_FAILED,
  SELECTED_CARDS, UNSELECT_CARD, UNSELECT_CARDS, UPDATE_BOARD, FETCH_STATE,
  CLEAR_STATE,
  SAVE_BOARD, SAVE_BOARDLAYOUT_SUCCEEDED, SAVE_BOARDLAYOUT_FAILED,
  BOARD_CARDS_COUNT_FETCH_SUCCEEDED, BOARD_CARDS_COUNT_FETCH_FAILED,
  BOARD_TAGS_FETCH_SUCCEEDED, BOARD_TAGS_FETCH_FAILED,
  BOARD_ATTACHMENTS_SIZE_FETCH_SUCCEEDED,
  BOARD_ATTACHMENTS_SIZE_FETCH_FAILED, BOARD_USERS_FETCH_SUCCEEDED,
  BOARD_USERS_FETCH_FAILED, SAVED_FILTERS_FETCH_SUCCEEDED,
  SAVED_FILTERS_FETCH_FAILED, CARD_META_FETCH_SUCCEEDED,
  CARD_META_FETCH_FAILED, BOARD_EXTERNAL_TOOLS_FETCH_SUCCEEDED,
  BOARD_EXTERNAL_TOOLS_FETCH_FAILED,FETCH_ARCHIVED_CARDS
} from '../actionTypes'

export const fetchBoard = (id) => ({
  type: BOARD_FETCH,
  payload: {
    id
  }
})
export const fetchArchivedCards = (id) => ({
  type: FETCH_ARCHIVED_CARDS,
  payload: {
    id
  }
})
export const fetchBoardRequested = (id) => ({
  type: BOARD_FETCH_REQUESTED,
  payload: {
    id
  }
})

export const fetchBoardSucceeded = (board,
  states,
  lanes,
  cards) => ({
  type: BOARD_FETCH_SUCCEEDED,
  payload: {
    board,
    states,
    lanes,
    cards
  }
})

export const fetchBoardFailed = (id, error) => ({
  type: BOARD_FETCH_FAILED,
  payload: {
    id,
    error
  }
})

export const selectedCards = (cards) => ({
  type: SELECTED_CARDS,
  payload: {
    cards
  }
})

export const unselectCard = (id) => ({
  type: UNSELECT_CARD,
  payload: {
    id
  }
})

export const unselectCards = (cards) => ({
  type: UNSELECT_CARDS,
  payload: {
    cards
  }
})

export const updateBoard = (id, data) => ({
  type: UPDATE_BOARD,
  payload: {
    id,
    data
  }
})

export const fetchState = (param) => ({
  type: FETCH_STATE,
  payload: {
    param
  }
})

export const clearState = (data, param) => ({
  type: CLEAR_STATE,
  payload: {
    data,
    param
  }
})

export const saveBoardDetails = (id) => ({
  type: SAVE_BOARD,
  payload: {
    id
  }
})

export const saveBoardLayoutSucceeded = (id, data, lanes) => ({
  type: SAVE_BOARDLAYOUT_SUCCEEDED,
  payload: {
    id,
    data,
    lanes
  }
})

export const saveBoardLayoutFailed = (id) => ({
  type: SAVE_BOARDLAYOUT_FAILED,
  payload: {
    id
  }
})

export const fetchBoardCardsCountSucceeded = (id, updatedLanes) => ({
  type: BOARD_CARDS_COUNT_FETCH_SUCCEEDED,
  payload: {
    id,
    updatedLanes
  }
})

export const fetchCardMetaSucceeded = (id, data) => ({
  type: CARD_META_FETCH_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const fetchCardMetaFailed = (id, error) => ({
  type: CARD_META_FETCH_FAILED,
  payload: {
    id,
    error
  }
})

export const fetchBoardCardsCountFailed = (id, error) => ({
  type: BOARD_CARDS_COUNT_FETCH_FAILED,
  payload: {
    id,
    error
  }
})
export const fetchBoardTagsSucceeded = (id, data) => ({
  type: BOARD_TAGS_FETCH_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const fetchBoardTagsFailed = (id, error) => ({
  type: BOARD_TAGS_FETCH_FAILED,
  payload: {
    id,
    error
  }
})

export const fetchBoardAttachmentSizeSucceeded = (id, data) => ({
  type: BOARD_ATTACHMENTS_SIZE_FETCH_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const fetchBoardAttachmentSizeFailed = (id, error) => ({
  type: BOARD_ATTACHMENTS_SIZE_FETCH_FAILED,
  payload: {
    id,
    error
  }
})

export const fetchBoardUsersSucceeded = (id, data) => ({
  type: BOARD_USERS_FETCH_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const fetchBoardUsersFailed = (id, error) => ({
  type: BOARD_USERS_FETCH_FAILED,
  payload: {
    id,
    error
  }
})

export const fetchSavedFiltersSucceeded = (id, data) => ({
  type: SAVED_FILTERS_FETCH_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const fetchSavedFiltersFailed = (id, error) => ({
  type: SAVED_FILTERS_FETCH_FAILED,
  payload: {
    id,
    error
  }
})

export const fetchBoardExternalToolSucceeded = (id, data) => ({
  type: BOARD_EXTERNAL_TOOLS_FETCH_SUCCEEDED,
  payload: {
    id,
    data
  }
})

export const fetchBoardExternalToolsFailed = (id, error) => ({
  type: BOARD_EXTERNAL_TOOLS_FETCH_FAILED,
  payload: {
    id,
    error
  }
})