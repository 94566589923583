import axios from 'axios'
import axiosInstance from '../../middleware/api'

const {
  CancelToken
} = axios
let cancel
function getCardsBySearch (boardID, term) {
  if (cancel !== undefined) {
    cancel()
  }
  /* eslint max-len:0*/
  return axiosInstance.get(`/api/v2/board/cardsbysearchkey?boardID=${boardID}${term ? `&term=${term}` : ''}`,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
}
function updateCardState (input) {
  return axiosInstance.put('/api/v2/card/subtaskstate', input)
}
function addComments (input) {
  return axiosInstance.post('/api/v2/card/comment', input)
}
function getComments (id) {
  return axiosInstance.get(`/api/v2/card/comments?id=${id}`)
}

// Backend Api for edit comments
function postComments (input) {
  return axiosInstance.put('/api/v2/card/comment', input)
}
// Backend Api for Delete Comments
function deleteComments (id, crdid) {
  return axiosInstance
    .delete(`/api/v2/card/comment?commentid=${id}&id=${crdid}`)
}
function cardTimeline (id) {
  return axiosInstance.get(`/api/v2/card/timeline?id=${id}`)
}
function getCardCycleTime (id) {
  return axiosInstance.get(`/api/v2/card/cycletime?id=${id}`)
}
function getCardCreatedModified (id) {
  return axiosInstance.get(`/api/v2/card/createdmodified?id=${id}`)
}
function linkParentChildCards (input) {
  return axiosInstance.put('/api/v2/card/linkcards', input)
}
function getChildCards (id) {
  return axiosInstance.get(`/api/v2/card/childcards?id=${id}`)
}
function getBoardLanes (id) {
  return axiosInstance.get(`/api/v2/lane/lanes?boardid=${id}`)
}
function getTagsForBoard (id) {
  return axiosInstance.get(`/api/v2/board/tags?id=${id}`)
}
function getCardDetails (id) {
  return axiosInstance.get(`/api/v2/card/carddetails?id=${id}`)
}
function getParentCardDetails (id) {
  return axiosInstance.get(`/api/v2/card/parentcard?id=${id}`)
}
function getSubTasks (id) {
  return axiosInstance.get(`/api/v2/card/subtasks?id=${id}`)
}
function updateParentCard (input) {
  return axiosInstance.put('/api/v2/card/parentcard', input)
}
function getBoardsForUser (name) {
  return axiosInstance.get(`/api/v2/board/boardsForUser?username=${name}`)
}
function getBoardForChildCards (cardId) {
  return axiosInstance.get(`/api/v2/board/boardsforchildcards?cardid=${cardId}`)
}
function getBoardForParentCards (cardId) {
  return axiosInstance.get(`/api/v2/board/boardsforparentcards?cardid=${cardId}`)
}
/* eslint max-len:0*/
function deleteCard (id, laneid, boardid, assignedUser, cardType, parentCard) {
  return axiosInstance.delete(`/api/v2/card/card?id=${id}&laneid=${laneid}&boardid=${boardid}&assigned_user=${assignedUser}&cardType=${cardType}&parentCard=${parentCard}`)
}
function cloneCard (id, onlysubtask) {
  return axiosInstance.post('/api/v2/card/clone', {
    id,
    onlysubtask
  })
}
function bulkCloneCard (input) {
  return axiosInstance.post('/api/v2/card/bulkclone', input)
}
function getArchivedCards(id, page, searchValue) {
  if (cancel !== undefined) {
    cancel()
  }
  return axiosInstance.get(`/api/v2/card/archivedCards?boardid=${id}&pageNumber=${page}&searchKey=${searchValue || ''}`,
    {
      cancelToken: new CancelToken((c) => {
        cancel = c
      })
    })
}
function getMyCardsInBoard (id) {
  return axiosInstance.get(`/api/v2/card/mycards?boardid=${id}`)
}
function getMySubTasks (id) {
  return axiosInstance.get(`/api/v2/card/mysubtasks?boardid=${id}`)
}
function getCardLinks (id) {
  return axiosInstance.get(`/api/v2/card/cardlinks?id=${id}`)
}
function getTimeSpentOnEachLane (id) {
  return axiosInstance.get(`/api/v2/card/timeSpentOneachLane?id=${id}`)
}
function getchildCardComments (id) {
  return axiosInstance.get(`/api/v2/card/childcardcomments?id=${id}`)
}
function getWatchersApi (cardId) {
  return axiosInstance.get(`/api/v2/card/watchers?id=${cardId}`)
}
function watchersApi (input) {
  return axiosInstance.post('/api/v2/card/watchers', input)
}

const cardDetailsService = {
  getCardsBySearch,
  updateCardState,
  addComments,
  getComments,
  cardTimeline,
  getCardCycleTime,
  getCardCreatedModified,
  linkParentChildCards,
  getChildCards,
  getBoardForChildCards,
  getBoardForParentCards,
  getBoardLanes,
  getTagsForBoard,
  getCardDetails,
  getParentCardDetails,
  getSubTasks,
  updateParentCard,
  getBoardsForUser,
  deleteCard,
  cloneCard,
  getArchivedCards,
  postComments,
  deleteComments,
  getMyCardsInBoard,
  bulkCloneCard,
  getCardLinks,
  getchildCardComments,
  getTimeSpentOnEachLane,
  getMySubTasks,
  getWatchersApi,
  watchersApi
}

export default cardDetailsService
